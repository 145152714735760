/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';

import { EnumUserStatus, EnumUserStatusClass, EnumUserStatusName, EnumUserType } from '../../../common/enums';
import ConfirmModal from '../../../common/modals/DeleteConfirmModal';
import { StudentSearchResult, UserModel } from '../../../common/models/_models';
import { deleteStudentUser, searchStudentUser, updateUserStatus } from '../core/_requests';
import ImportUserFromExcelModal from '../modals/ImportUserFromExcelModal';
import UpsertStudentModal from '../modals/UpsertStudentModal';
import moment from 'moment';


const StudentPage: React.FC = () => {
  const [students, setStudents] = useState<StudentSearchResult[]>([]);
  const [loading, setLoading] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentTerm, setCurrentTerm] = useState<string>();
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [showImport, setShowImport] = useState(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [selectedStudent, setSelectedStudent] = useState<StudentSearchResult>();

  const handleClose = () => setShow(false);

  const columns = [
    {
      name: 'Họ và tên',
      selector: row => `${row.firstName} ${row.lastName}`,
    },
    {
      name: 'Email',
      selector: row => row.email,
    },
    {
      name: 'Số điện thoại',
      selector: row => row.phoneNumber,
    },
    {
      name: 'Trạng thái',
      selector: row => <div className={'badge badge-' + EnumUserStatusClass[row.status]} >{EnumUserStatusName[row.status]}</div>,
    },
    {
      name: 'Ngày tạo',
      selector: row => moment.unix(row.created).format("DD/MM/YYYY"),
    },
    {
      name: '',
      selector: row => renderActionButton(row),
      width: '150px',
      right: true
    },
  ] as TableColumn<StudentSearchResult>[];

  const renderActionButton = (row: StudentSearchResult) => {
    return (
      <div>
        <div className='d-flex justify-content-end flex-shrink-0'>

          <button onClick={() => handleUpsertUser(row.id)}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <span className="fs-3 material-symbols-rounded">
              edit
            </span>
          </button>


          <button onClick={() => handleChangeUserStatus(row.id, (row.status === EnumUserStatus.Actived ? EnumUserStatus.Disabled : EnumUserStatus.Actived))}
            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'>
            <span className="fs-3 material-symbols-rounded">
              {row.status === EnumUserStatus.Actived ? 'person_off' : 'person'}
            </span>
          </button>

          <button onClick={() => handleDeleteConfirm(row.id)}
            className='btn btn-icon btn-bg-light btn-active-color-danger btn-sm' >
            <span className="fs-3 material-symbols-rounded">
              delete
            </span>
          </button>
        </div>
      </div>
    )
  }

  const fetchUsers = async (page: any, term?: string) => {
    setLoading(true);
    setCurrentPage(page)
    setCurrentTerm(term)

    const { data } = await searchStudentUser({ pageIndex: page, pageSize: perPage, term: term || '' });

    setStudents(data.data);
    setTotalRows(data.total);
    setLoading(false);
  };

  const handlePageChange = (page: any) => {
    fetchUsers(page);
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setLoading(true);

    const { data } = await searchStudentUser({ pageIndex: page, pageSize: newPerPage, term: '' });
    setStudents(data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchUsers(1);
  }, []);

  const handleUpsertUser = (id?: string) => {
    if (id) {
      let teacher = students.find(x => x.id === id);
      if (!teacher) {
        alert("User not found!");
        return;
      }
      setSelectedStudent(teacher)
    } else {
      setSelectedStudent(undefined)
    }
    setShow(true);
  }

  const handleChangeUserStatus = async (id: string, status: string) => {
    if (id) {
      const { data } = await updateUserStatus(id, status);
      if (data.data) {
        let index = students.findIndex(x => x.id === id);
        if (index > -1) {
          let newStudent = [...students];
          newStudent[index].status = status;
          setStudents(newStudent)
        }
      }
    }
  }

  const handleDelete = async () => {
    if (selectedId) {
      const { data } = await deleteStudentUser(selectedId);
      if (data.data) {
        fetchUsers(1);
        setSelectedId(undefined)
        setShowDelete(false);
      }
    }

  }

  const handleDeleteConfirm = (id: string) => {
    setSelectedId(id)
    setShowDelete(true)
  }

  const handleCloseDeleteConfirm = () => {
    setSelectedId(undefined)
    setShowDelete(false)
  }

  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      fetchUsers(1, event.target.value);
    }
  }

  const handleUpdateUserSuccess = async () => {
    if (selectedStudent) {
      setSelectedStudent(undefined)
    }
    fetchUsers(currentPage, currentTerm)
    setShow(false)
  }
  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Danh sách học viên</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Tổng cộng {totalRows} học viên</span>
          </h3>
          <div className='d-flex align-items-center'>
            <div className='me-2'>
              <input type='search' className='form-control form-control-sm' placeholder='Enter để tìm kiếm...' onKeyDown={handleKeyDown} />
            </div>

            <button
              onClick={() => setShowImport(true)}
              className='btn btn-sm btn-light-primary d-flex me-2'
            >
              <span className="fs-3 material-symbols-rounded me-2">
                upload
              </span>
              Import
            </button>


            <div
              className='card-toolbar'
              data-bs-toggle='tooltip'
              data-bs-placement='top'
              data-bs-trigger='hover'
              title='Click to add a user'
            >
              <button
                onClick={() => handleUpsertUser()}
                className='btn btn-sm btn-primary d-flex'

              >
                <span className="fs-3 material-symbols-rounded me-2">
                  add
                </span>
                Thêm học viên
              </button>
            </div>
          </div>
        </div>

        <div className='card-body' hidden={loading}>
          <DataTable
            paginationComponentOptions={{
              rowsPerPageText: "Số hàng",
              rangeSeparatorText: "trong"
            }}
            columns={columns}
            data={students}
            progressPending={loading}
            pagination
            paginationServer
            paginationTotalRows={totalRows}
            onChangeRowsPerPage={handlePerRowsChange}
            onChangePage={handlePageChange}
            responsive
            noDataComponent="Không có dữ liệu"
            highlightOnHover
          />
        </div>
        {loading && <>
          <div className='card  my-5'>
            <div className='card-body'>
              <div className="skeleton rounded" style={{ height: 150 }}></div>
            </div>
          </div>
        </>
        }
      </div>

      <UpsertStudentModal show={show} handleClose={handleClose} handleSuccess={handleUpdateUserSuccess} studentId={selectedStudent?.id} />
      <ConfirmModal show={showDelete} handleClose={handleCloseDeleteConfirm} handleConfirmed={handleDelete} />
      <ImportUserFromExcelModal show={showImport} handleClose={() => setShowImport(false)} handleSuccess={() => fetchUsers(1)} userType={EnumUserType.Student} />
    </>
  )
}

export default StudentPage
