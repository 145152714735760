import { useAuth } from '../../../../app/modules/auth';
import { MenuInnerWithSub } from './MenuInnerWithSub';
import { MenuItem } from './MenuItem';

export function MenuInner() {
  const { currentUser } = useAuth()
  return (
    <>

      <MenuItem
        title="Tổng quan"
        ggIcon='dashboard'
        to='/dashboard' />

      <MenuItem
        title="Lớp học"
        ggIcon='school'
        to='/class-rooms/list' />

      <MenuInnerWithSub
        title='Luyện tập'
        to='practice-collection'
        ggIcon='quiz'
        hasArrow={true}
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >

        <MenuItem to='/practice-collection/list' title='Bộ đề luyện tập' hasBullet={true} />
        <MenuItem to='/practice-collection/answers' title='Chấm điểm' hasBullet={true} />
        <MenuItem to='/exams/practice-collection' title='Đề thi' hasBullet={true} />

      </MenuInnerWithSub>


      <MenuInnerWithSub
        title='Bài thi'
        to='tests'
        ggIcon='clinical_notes'
        hasArrow={true}
        menuPlacement='bottom-start'
        menuTrigger={`{default:'click', lg: 'hover'}`}
      >
        <MenuItem to='/tests/list' title='Lịch thi' hasBullet={true} />
        <MenuItem to='/exams/tests' title='Đề thi' hasBullet={true} />

      </MenuInnerWithSub>

      {
        currentUser?.userType === 'Admin' &&
        <MenuInnerWithSub
          title='Người dùng'
          to='users'
          ggIcon='group'
          hasArrow={true}
          menuPlacement='bottom-start'
          menuTrigger={`{default:'click', lg: 'hover'}`}
        >
          <MenuItem to='/users/teachers' title='Giáo viên' hasBullet={true} />
          <MenuItem to='/users/students' title='Học viên' hasBullet={true} />
          <MenuItem to='/users/admins' title='Quản trị viên' hasBullet={true} />
        </MenuInnerWithSub>
      }



    </>
  )
}
