export enum EnumUserStatus {
    Disabled = "Disabled",
    Actived = "Actived",
    Banned = "Banned"
}

export const EnumUserStatusName = {
    Disabled: "Ngừng hoạt động",
    Actived: "Hoạt động",
    Banned: "Bị cấm"
} as any;;

export const EnumUserStatusClass = {
    Disabled: "secondary",
    Actived: "success",
    Banned: "danger"
} as any;