/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx';
import { FC } from 'react';

import { toAbsoluteUrl } from '../../../helpers';
import { HeaderUserMenu } from '../../../partials';
import { useAuth } from '../../../../app/modules/auth';





const Topbar: FC = () => {
  const toolbarButtonMarginClass = 'ms-1 ms-lg-3',
    toolbarUserAvatarHeightClass = 'symbol-30px symbol-md-40px'

  const { currentUser } = useAuth();
  return (
    <div className='d-flex align-items-stretch flex-shrink-0'>
      <div className='topbar d-flex align-items-stretch flex-shrink-0'>


        {/* begin::User */}
        <div
          className={clsx('d-flex align-items-center', toolbarButtonMarginClass)}
          id='kt_header_user_menu_toggle'
        >
          <div className='me-2 text-uppercase fw-bold'>{currentUser?.firstName} {currentUser?.lastName}</div>

          {/* begin::Toggle */}
          <div
            className={clsx('cursor-pointer symbol', toolbarUserAvatarHeightClass)}
            data-kt-menu-trigger='click'
            data-kt-menu-attach='parent'
            data-kt-menu-placement='bottom-end'
            data-kt-menu-flip='bottom'
          >
            <img
              className='h-30px w-30px rounded'
              src='data:image/jpeg;base64,/9j/4AAQSkZJRgABAQAAAQABAAD/2wCEAAkGBxATBggQBwgKCg0NBw0IDQgHCA8ICQgNFB0XFhURExMYHSggGBolGxMTITEhMSkrLi46Fx8zODMsNygtLisBCgoKDQ0NDw0NDisZExkrKysrKys3LSsrKysrKzcrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrKysrK//AABEIAMgAyAMBIgACEQEDEQH/xAAaAAEAAwEBAQAAAAAAAAAAAAAAAwQFAgEH/8QAMxABAAIBAgIGCQMFAQAAAAAAAAECAwQREiEiMTNBUXIFMlJTYXGRorEUgYIjQmKh0RP/xAAWAQEBAQAAAAAAAAAAAAAAAAAAAQL/xAAYEQEBAQEBAAAAAAAAAAAAAAAAARExEv/aAAwDAQACEQMRAD8A+qANMAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAERz5RMz4VAFvFoZmP6k8EeHrWWaaKkddZt5pTVxljX/TU93VxfRUnqia+WTTGWLeXRWiJ/85448PVsqTHPnG0+CgAIAAAAAAAAAAAAAAAA6x0mbRFY33aen08Vjxt32c6LBw497R0p+1ZZakegCgACDUaeLR4W7rpwGJkrMWmLxtMOWnrcPFTesdKOfzhmKxQBQAAAAAAAAAAAATaWnFnrE9UdKUK76Nr08k+FYqhGgAjYAAAAAAyNVThz226p6UNdn+kq9PHPjWarEqkArIAAAAAAAAAAAAu+jZ7T9lJZ0Fts3PqtXZCNQBGwAAAAAHij6Snnj8srzM19t8/yrsRLxWAaZAAAAAAAAAAAAHtbbWiY64txQ8BWzhycWOsx3/6dsrSZ+G3Peaz1/wCPxalbRMRNZiYnqmGVldACgAAObWiKzNp2iOuQc5snDjtM93V8WPM72mZ65txSm1efity5VjqQLGLQBQAAAAAAAAAAAAAATYNRas8ucd9JQgrVxaqs9/DPs3WGE7rktHq3tHllMJW08ZH6i/vLfVxa9p9a9p81jD008uqrG/Pin2aKGfPa0852jurCEMS0AUAAAAAAAAAAAAAAB1SszO1KzafguYdD7238aoqj3pqaa89VJiPG3RaePFWI6FYj9khq4za6G3916x9zuNB45PpReDTIpfoI95b6PJ9H+GT60XhNMjOtobf23rP2or6a8ddJmPGvSa4umMLv5jZyYazHTrE/HZUy6H3Vv4X/AOmpiiOr1mLbXrMT8XKoAAAAAAAAAALGm002525V8fae6PT8U739WPuacRy5JqyOceOK12pERDsEaAAAAAAAAAAcZMcWrteN4Zup001516VfH2Wq8mOXMSzWGLOs0/DO9PVn7VZpAAQAAAASYcfFkrEfvPs1RtD0fj6NrT1zO0fJKRbpXasRWNoiu0OgRsAAAAAAAAAAAAABzesTWYtG8TylkZsfDktE93VPtQ2VL0jTo1tHdyn5LErPAVkAAAAa2ljbBj8m7JbODscfkr+EqxIAjQAAAAAAAAAAAAAAh1cb6fJ5N0yPP2OTySDGAaZABAABs4Oxx+Sv4YzZwdjj8lfwlaiQBFAAAAAAAAAAAAAAEefscnkt+EiPP2OTyW/AMYBpkAEAAGzg7HH5K/gErUSAIoAAAAAAAAAAAAAAjz9jk8lvwAMYBpkAEf/Z'
              alt='metronic'
            />
          </div>
          <HeaderUserMenu />
          {/* end::Toggle */}
        </div>
        {/* end::User */}
      </div>
    </div>
  )
}

export { Topbar }
