import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';

type props = {
    handleClose: () => void,
    show: boolean,
    handleConfirmed: () => void,
    loading?: boolean
    title?: string
    variant?: string
    actionName?: string
}

const ConfirmModal: FC<props> = ({
    show,
    handleClose,
    handleConfirmed,
    loading = false,
    title = 'Xác nhận xoá',
    variant = 'danger',
    actionName = 'Xóa'
}) => {


    return (
        <Modal show={show} onHide={handleClose} size="sm">
            <Modal.Header>
                <Modal.Title>{title}</Modal.Title>
            </Modal.Header>
            <Modal.Footer>
                <Button variant="secondary" type="button" onClick={handleClose} disabled={loading}>
                    Đóng
                </Button>
                <Button variant={variant} onClick={handleConfirmed} disabled={loading}>
                    {actionName}
                </Button>
            </Modal.Footer>

        </Modal>
    )
}

export default ConfirmModal
