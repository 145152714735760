export enum EnumAttendanceType {
    Absent = "Absent",
    Enrolled = "Enrolled",
    Tardy = "Tardy",
}

export const EnumAttendanceTypeToString = {
    Absent: "🔴 Vắng mặt",
    Enrolled: "🟢 Có mặt",
    Tardy: "🟠 Đến muộn",
} as any