import React, { useEffect, useState } from "react"
import { getReportDashboard } from "../core/_requests"
import { ReportDashboardModel } from "../core/_models"

type props = {
    value: number
    variant: string
    icon: string
    title: string
}
const ReportItem: React.FC<props> = ({ value, variant, icon, title }) =>
    <div className='col-sm-4'>
        <div className={`bg-light-${variant} px-6 py-8 rounded-2 d-flex justify-content-between align-items-center`}>
            <div>
                <span className={`material-symbols-rounded fs-3x text-${variant} d-block my-2`}>
                    {icon}
                </span>
                <a href="#" className={`text-${variant} fw-semibold fs-6 mt-2`}>{title}</a>
            </div>
            <h1 className={`text-${variant}`}>
                {value.toLocaleString()}
            </h1>
        </div>
    </div>

const DashboardReport = () => {

    const [first, setfirst] = useState<ReportDashboardModel>();

    const handleGetReport = async () => {
        let { data } = await getReportDashboard();
        if (data.data) {
            setfirst(data.data);
        }
    }
    useEffect(() => {
        handleGetReport();
    }, [])

    return (



        <div className="row">
            {
                first &&
                <>
                    <ReportItem value={first.numberClassRoom} variant="primary" icon="school" title="Số lớp học" />
                    <ReportItem value={first.numberStudent} variant="success" icon="group" title="Số học viên" />
                    <ReportItem value={first.numberTeacher} variant="warning" icon="group" title="Số giáo viên" />
                </>
            }
        </div>


    )
}

export default DashboardReport
