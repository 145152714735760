import { FC, lazy, Suspense } from 'react'
import { Navigate, Route, Routes } from 'react-router-dom'
import { MasterLayout } from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import { getCSSVariableValue } from '../../_metronic/assets/ts/_utils'
import { WithChildren } from '../../_metronic/helpers'
import { useAuth } from '../modules/auth'
import { DashboardTeacherWrapper } from '../pages/dashboard/DashboardTeacherWrapper'
import { DashboardWrapper } from '../pages/dashboard/DashboardWrapper'


const PrivateRoutes = () => {
  const UsersPage = lazy(() => import('../modules/users/UsersPage'))
  const PracticeCollectionPage = lazy(() => import('../modules/practice-collection/PracticeCollectionPage'))
  const ExamsPage = lazy(() => import('../modules/exams/ExamsPage'))
  const ClassRoomsPage = lazy(() => import('../modules/class-rooms/ClassRoomsPage'))
  const TestsPage = lazy(() => import('../modules/tests/TestsPage'))
  const { currentUser } = useAuth()

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='dashboard' element={currentUser?.userType === 'Admin' ? <DashboardWrapper /> : <DashboardTeacherWrapper />} />
        {
          currentUser?.userType === 'Admin' &&
          <Route
            path='users/*'
            element={
              <SuspensedView>
                <UsersPage />
              </SuspensedView>
            }
          />
        }

        <Route
          path='class-rooms/*'
          element={
            <SuspensedView>
              <ClassRoomsPage />
            </SuspensedView>
          }
        />
        <Route
          path='tests/*'
          element={
            <SuspensedView>
              <TestsPage />
            </SuspensedView>
          }
        />
        <Route
          path='practice-collection/*'
          element={
            <SuspensedView>
              <PracticeCollectionPage />
            </SuspensedView>
          }
        />
        <Route
          path='exams/*'
          element={
            <SuspensedView>
              <ExamsPage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>

}

export { PrivateRoutes }
