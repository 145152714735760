import { Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';

import { toAbsoluteUrl } from '../../../../_metronic/helpers';
import { ImportUserModel, ImportUserResult } from '../core/_models';
import { importExcel } from '../core/_requests';
import clsx from 'clsx';

type props = {
    handleClose: () => void,
    handleSuccess: () => void,
    show: boolean,
    userType: string
    classRoomId?: string
}

const ValidateSchema = Yup.object().shape({
    file: Yup.mixed().required('Vui lòng chọn file')
});

const ImportUserFromExcelModal: FC<props> = ({ show, handleClose, userType, handleSuccess, classRoomId }) => {
    const [initialValues, setInitialValues] = useState<ImportUserModel>({ file: null });
    const [result, setResult] = useState<ImportUserResult[]>([])
    const [loading, setLoading] = useState(false)

    const handleImport = async (values: ImportUserModel) => {
        setLoading(true)
        let { data } = await importExcel(userType, values, classRoomId);
        if (data.data) {
            if (data.data.some(x => x.success)) {
                handleSuccess();
            }

            setResult(data.data);
            setInitialValues({ file: null });
        }
        setLoading(false)
    }

    useEffect(() => {
        setResult([])
    }, [show])

    return (
        <Modal show={show} onHide={handleClose} size="lg" backdrop="static" keyboard={false}>
            <Modal.Header closeButton className='border-0 pb-0'>
                <Modal.Title>
                    Import người dùng
                    <div className='small fw-normal text-muted'>Tải lên người dùng theo <a href='https://drive.google.com/drive/folders/1Ja7Xg1tZdVUWCzBvDW3JbzqFi81-Qoll?usp=drive_link' target='_blank'>mẫu upload</a>, tối đa 200 người dùng 1 lần</div>
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={ValidateSchema}
                onSubmit={(values) => {
                    handleImport(values)
                }}
            >
                {({ setFieldValue, errors, values, touched }) => (
                    <Form>
                        <Modal.Body>

                            <div className='row'>

                                <div hidden={result.length > 0} className="col-lg-12 mb-3">
                                    <label className="col-form-label required fw-bold fs-6">File</label>

                                    <div className='border rounded p-5'>
                                        <label className='d-flex flex-column align-items-center my-5 cursor-pointer ' htmlFor="file">
                                            <span className="fs-3x material-symbols-rounded">
                                                upload
                                            </span>
                                            <span>Chọn file upload</span>
                                            <span className='text-muted'>.xls hoặc .xlsx</span>
                                            {errors.file && <div className='text-danger small'>{errors.file.toString()}</div>}

                                        </label>

                                        <input
                                            hidden
                                            type="file"
                                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                                            onChange={(e) => {
                                                if (e.currentTarget.files) {
                                                    setFieldValue("file", e.currentTarget.files[0]);
                                                }
                                            }}
                                            name="file"
                                            id='file'
                                        />

                                        {values.file?.name &&
                                            <div className="border p-3 rounded bg-light">
                                                <img width={30} src={toAbsoluteUrl('/media/excel-file.png')} className='me-2' />
                                                {values.file.name}
                                            </div>
                                        }

                                    </div>

                                </div>

                                <div className='col-sm-12'>
                                    <label hidden={!result.length} className="col-form-label fw-bold fs-6">
                                        Kết quả {result.filter(x => x.success).length}/{result.length}
                                    </label>

                                    <div style={{ maxHeight: 300 }} className='overflow-scroll'>
                                        {
                                            result.map(x =>
                                                <div key={x.email} className='d-flex mb-3'>

                                                    <span className={clsx("me-2 material-symbols-rounded", x.success ? 'text-success' : 'text-danger')}>
                                                        check_circle
                                                    </span>
                                                    <div>
                                                        <div>{x.email} </div>
                                                        {x.message}

                                                    </div>
                                                </div>
                                            )
                                        }
                                    </div>

                                </div>

                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button hidden={result.length > 0} variant="primary" type="submit" disabled={loading}>
                                Import
                            </Button>
                            <Button hidden={!result.length} variant="primary" type="button" onClick={handleClose} disabled={loading}>
                                Đóng
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            </Formik>
        </Modal>
    )
}

export default ImportUserFromExcelModal
