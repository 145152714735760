import React from 'react'
import { Link } from 'react-router-dom'
import { useLocation } from 'react-router'
import clsx from 'clsx'
import { checkIsActive, KTIcon } from '../../../helpers'
import { fail } from 'assert'

type Props = {
  to: string
  title: string
  icon?: string
  fontIcon?: string
  hasArrow?: boolean
  hasBullet?: boolean
  isChild?: boolean
  ggIcon?: string
}

const MenuItem: React.FC<Props> = ({
  to,
  title,
  icon,
  fontIcon,
  hasArrow = false,
  hasBullet = false,
  isChild = false,
  ggIcon
}) => {
  const { pathname } = useLocation()

  return (
    <div className='menu-item me-lg-1'>
      <Link
        className={clsx('menu-link py-3', {
          active: checkIsActive(pathname, to),
        }, isChild ? 'menu-child-items' : '')}
        to={to}
      >
        {hasBullet && (
          <span className='menu-bullet'>
            <span className='bullet bullet-dot'></span>
          </span>
        )}

        {icon && (
          <span className='menu-icon'>
            <KTIcon iconName={icon} className='fs-2' />
          </span>
        )}

        {fontIcon && (
          <span className='menu-icon'>
            <i className={clsx('bi fs-3', fontIcon)}></i>
          </span>
        )}

        <span className='menu-title'>
          {ggIcon && (
            <span className="material-symbols-rounded me-2 fs-2">
              {ggIcon}
            </span>
          )}
          {title}
        </span>

        {hasArrow && <span className='menu-arrow'></span>}
      </Link>
    </div>
  )
}

export { MenuItem }
