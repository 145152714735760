export enum EnumExamType {
    Practice = "Practice",
    Test = "Test",
}


export const EnumExamToString = {
    "Practice" : "Luyện tập",
    "Test" : "Kiểm tra"
} as any
