import axios from 'axios';

import { BaseResponse } from '../../../common/models/_models';
import { ReportDashboardModel } from './_models';

const API_URL = process.env.REACT_APP_API_URL

export const TEST_API = `${API_URL}/api/reports`

export function getReportDashboard() {
  return axios.get<BaseResponse<ReportDashboardModel>>(`${TEST_API}/dashboard`)
}

