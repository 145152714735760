import { Field, Form, Formik } from 'formik';
import { FC, useEffect, useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import * as Yup from 'yup';

import { UpsertStudentModel } from '../../../common/models/_models';
import { createStudentUser, getStudentById, updateStudentUser } from '../core/_requests';

const validationSchema = Yup.object().shape({
    studentContact: Yup.object().shape({
        firstName: Yup.string()
            .min(2, 'Quá ngắn!')
            .max(50, 'Quá dài!')
            .required('Không được để trống'),

        lastName: Yup.string()
            .min(2, 'Quá ngắn!')
            .max(50, 'Quá dài!')
            .required('Không được để trống'),

        email: Yup.string()
            .min(2, 'Quá ngắn!')
            .max(300, 'Quá dài!')
            .email('Không đúng định dạng')
            .required('Không được để trống'),

        phoneNumber: Yup.string()
            .min(2, 'Quá ngắn!')
            .max(50, 'Quá dài!')
            .required('Không được để trống'),

        password: Yup.string()
            .min(2, 'Quá ngắn!')
    }),
    studentParentContact: Yup.object().shape({
        parentEmail: Yup.string()
            .min(2, 'Quá ngắn!')
            .max(300, 'Quá dài!')
            .email('Không đúng định dạng'),

        parentName: Yup.string()
            .min(2, 'Quá ngắn!')
            .max(100, 'Quá dài!'),

        parentPhone: Yup.string()
            .min(2, 'Quá ngắn!')
            .max(50, 'Quá dài!'),
    }),
});


type props = {
    handleClose: () => void,
    show: boolean,
    studentId?: string,
    handleSuccess: () => void
}

const defaultValue = {
    studentContact: {
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        password: ''
    },
    studentParentContact: {
        parentEmail: '',
        parentName: '',
        parentPhone: '',
    }
}

const UpsertStudentModal: FC<props> = ({ show, handleClose, handleSuccess, studentId }) => {
    const [loading, setLoading] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);
    const [initialValues, setInitialValues] = useState<UpsertStudentModel>(defaultValue);

    useEffect(() => {
        if (studentId) {
            getStudents()
        } else {
            setInitialValues(defaultValue);
        }
    }, [studentId])

    const getStudents = async () => {
        if (studentId) {
            setLoading(true);
            const { data } = await getStudentById(studentId);

            if (data.data) {
                let student = data.data;
                setInitialValues({
                    studentContact: {
                        firstName: student?.firstName || '',
                        lastName: student?.lastName || "",
                        email: student?.email || "",
                        phoneNumber: student?.phoneNumber || "",
                        password: ""
                    },
                    studentParentContact: {
                        parentEmail: student?.parentEmail || "",
                        parentName: student?.parentName || "",
                        parentPhone: student?.parentPhone || "",
                    }
                });
            }

            setLoading(false)
        }
    }

    const handleCloseModal = () => {
        setInitialValues(defaultValue)
        handleClose()
    }

    const handleUpsertStudent = async (request: UpsertStudentModel) => {
        setLoadingUpdate(true)
        try {
            if (studentId) {
                const { data } = await updateStudentUser(studentId, request);
                if (data.data) {
                    handleSuccess()
                }
            } else {
                const { data } = await createStudentUser(request);
                if (data.data) {
                    handleSuccess()
                }
            }
        } finally {
            setLoadingUpdate(false)
        }
    }

    return (
        <Modal show={show} onHide={handleCloseModal} size="lg">
            <Modal.Header closeButton>
                <Modal.Title>
                    {studentId && <span>Sửa học viên</span>}
                    {!studentId && <span>Thêm học viên</span>}
                </Modal.Title>
            </Modal.Header>
            {
                loading
                    ?
                    <Modal.Body>
                        <div className='pb-5'>
                            <div className="skeleton rounded my-5" style={{ height: 30, width: 300 }}></div>
                            <div className="skeleton rounded my-5" style={{ height: 50 }}></div>
                            <div className="skeleton rounded my-5" style={{ height: 50 }}></div>
                            <div className="skeleton rounded my-5" style={{ height: 50 }}></div>
                        </div>
                        <div className='pt-5'>
                            <div className="skeleton rounded my-5" style={{ height: 30, width: 300 }}></div>
                            <div className="skeleton rounded my-5" style={{ height: 50 }}></div>
                            <div className="skeleton rounded my-5" style={{ height: 50 }}></div>
                            <div className="skeleton rounded my-5" style={{ height: 50 }}></div>
                        </div>
                    </Modal.Body>
                    :
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}

                        onSubmit={(values) => {
                            handleUpsertStudent(values);
                        }}
                    >
                        {({ errors, touched, isValid }) => (

                            <Form>
                                <Modal.Body>
                                    <div className='py-5'>
                                        <h3 className='mb-5'>Thông tin học sinh</h3>
                                        <div>
                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label required fw-bold fs-6">Họ và tên</label>
                                                <div className="col-lg-8">
                                                    <div className="row">
                                                        <div className="col-sm-6 fv-row">
                                                            <Field id="firstName" name="studentContact.firstName" className="form-control form-control-lg mb-3 mb-lg-0" placeholder="họ" />
                                                            {errors && touched.studentContact?.firstName ? (
                                                                <div className='text-danger small'>{errors.studentContact?.firstName}</div>
                                                            ) : null}
                                                        </div>
                                                        <div className="col-sm-6 fv-row">
                                                            <Field id="lastName" name="studentContact.lastName" placeholder="tên" className="form-control form-control-lg" />
                                                            {errors && touched.studentContact?.lastName ? (
                                                                <div className='text-danger small'>{errors.studentContact?.lastName}</div>
                                                            ) : null}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label required fw-bold fs-6">Email</label>
                                                <div className="col-lg-8">
                                                    <Field type="email" id="email" disabled={studentId} name="studentContact.email" placeholder="email" className="form-control form-control-lg mb-3 mb-lg-0" />
                                                    {errors && touched.studentContact?.email ? (
                                                        <div className='text-danger small'>{errors.studentContact?.email}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label required fw-bold fs-6">Số điện thoại</label>
                                                <div className="col-lg-8">
                                                    <Field type="phone" id="phoneNumber" name="studentContact.phoneNumber" placeholder="số điện thoại" className="form-control form-control-lg mb-3 mb-lg-0" />
                                                    {errors && touched.studentContact?.phoneNumber ? (
                                                        <div className='text-danger small'>{errors.studentContact?.phoneNumber}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">
                                                    {studentId && <span>Mật khẩu mới</span>}
                                                    {!studentId && <span>Mật khẩu</span>}
                                                </label>
                                                <div className="col-lg-8">
                                                    <Field type="password" id="password" name="studentContact.password" placeholder="mật khẩu" className="form-control form-control-lg mb-3 mb-lg-0" />
                                                    {errors && touched.studentContact?.password ? (
                                                        <div className='text-danger small'>{errors.studentContact?.password}</div>
                                                    ) : null}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div>
                                        <h3 className='mb-5'>Thông tin phụ huynh</h3>
                                        <div>

                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Họ và tên</label>
                                                <div className="col-lg-8">
                                                    <Field type="text" id="parentName" name="studentParentContact.parentName" placeholder="họ và tên" className="form-control form-control-lg mb-3 mb-lg-0" />
                                                    {errors && touched.studentParentContact?.parentName ? (
                                                        <div className='text-danger small'>{errors.studentParentContact?.parentName}</div>
                                                    ) : null}
                                                </div>
                                            </div>


                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Số điện thoại</label>
                                                <div className="col-lg-8">
                                                    <Field type="text" id="parentPhone" name="studentParentContact.parentPhone" placeholder="số điện thoại" className="form-control form-control-lg mb-3 mb-lg-0" />
                                                    {errors && touched.studentParentContact?.parentPhone ? (
                                                        <div className='text-danger small'>{errors.studentParentContact?.parentPhone}</div>
                                                    ) : null}
                                                </div>
                                            </div>

                                            <div className="row mb-6">
                                                <label className="col-lg-4 col-form-label fw-bold fs-6">Email</label>
                                                <div className="col-lg-8">
                                                    <Field type="email" id="parentEmail" name="studentParentContact.parentEmail" placeholder="email" className="form-control form-control-lg mb-3 mb-lg-0" />
                                                    {errors && touched.studentParentContact?.parentEmail ? (
                                                        <div className='text-danger small'>{errors.studentParentContact?.parentEmail}</div>
                                                    ) : null}
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" type="button" onClick={handleCloseModal}>
                                        Đóng
                                    </Button>
                                    <Button variant="primary" type="submit" disabled={!isValid || loadingUpdate}>
                                        {studentId && <span>Cập nhật</span>}
                                        {!studentId && <span>Thêm</span>}
                                    </Button>
                                </Modal.Footer>
                            </Form>
                        )}
                    </Formik>

            }

        </Modal>
    )
}

export default UpsertStudentModal
