/* eslint-disable jsx-a11y/anchor-is-valid */
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DataTable, { TableColumn } from 'react-data-table-component';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';

import ConfirmModal from '../../../common/modals/DeleteConfirmModal';
import { UserModel } from '../../../common/models/_models';
import { searchTeacherUser } from '../../users/core/_requests';
import { ClassRoomModel, UpsertClassRoomModel } from '../core/_models';
import { createClassRoom, deleteClassRoom, searchClassRoom, updateClassRoom } from '../core/_requests';
import UpsertClassRoomModal from '../modals/upsert-class-room/UpsertClassRoomModal';
import { EnumUserStatus } from '../../../common/enums';

export const Export = ({ onExport }: { onExport: () => void }) => (
  <button onClick={() => onExport()}>Export</button>
);

const ClassRoomListPage: React.FC = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [data, setData] = useState<ClassRoomModel[]>([]);
  const [loading, setLoading] = useState(false);
  const [loadingAction, setLoadingAction] = useState(false);
  const [totalRows, setTotalRows] = useState(0);
  const [perPage, setPerPage] = useState(10);
  const [show, setShow] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [selectedId, setSelectedId] = useState<string>();
  const [selectedTeacher, setSelectedTeacher] = useState<ClassRoomModel>();

  const handleClose = () => setShow(false);

  const columns = [
    {
      name: 'Tên lớp',
      selector: row => `${row.name}`,
      width: '40%'
    },
    {
      name: 'Trạng thái',
      width: '150px',
      selector: row => <>
        {
          row.isCompleted ?
            <div className='badge badge-success'>Đã xong</div>
            :
            <>
              {
                moment(row.startDate).isAfter(moment()) ?
                  <div className='badge badge-secondary'>Chưa bắt đầu</div>
                  :
                  <div className='badge badge-primary'>Đang học</div>

              }
            </>
        }
      </>,
    },
    {
      name: 'Ngày bắt đầu',
      selector: row => moment(row.startDate).format('DD/MM/YYYY'),
    },
    {
      name: 'Người tạo',
      selector: row => <div>
        {
          row.createdBy && <>
            <div>
              {row.createdBy?.firstName} {row.createdBy?.lastName}
            </div>
            <small>
              {row.createdBy?.email}
            </small>
          </>
        }

      </div>,
    },
    
  ] as TableColumn<ClassRoomModel>[];

  const fetchClassRoom = async (page: any, term: string) => {
    setLoading(true);

    const { data } = await searchClassRoom({ pageIndex: 1, pageSize: 99999, term: term });

    setData(data.data);
    setTotalRows(data.total);
    setLoading(false);
  };

  const handlePageChange = (page: any) => {
    fetchClassRoom(page, '');
  };

  const handlePerRowsChange = async (newPerPage: any, page: any) => {
    setLoading(true);

    const { data } = await searchClassRoom({ pageIndex: page, pageSize: newPerPage, term: '' });
    setData(data.data);
    setPerPage(newPerPage);
    setLoading(false);
  };

  useEffect(() => {
    fetchClassRoom(1, '');
    getTeachers();
    if (searchParams.get("openAdd")) {
      setShow(true);
    }
  }, [searchParams]);

  const handleCreate = async (request: UpsertClassRoomModel) => {
    if (selectedTeacher) {
      const { data } = await updateClassRoom(selectedTeacher.id, request);
      if (data.data) {
        fetchClassRoom(1, '');
        setSelectedTeacher(undefined)
        setShow(false);
      }
    } else {
      const { data } = await createClassRoom(request);
      if (data.data) {
        fetchClassRoom(1, '');
        setShow(false);
      }
    }

  }

  const handleCreateClassRoom = () => {
    setShow(true);
  }

  const handleDelete = async () => {
    if (selectedId) {
      const { data } = await deleteClassRoom(selectedId);
      if (data.data) {
        fetchClassRoom(1, '');
        setSelectedId(undefined)
        setShowDelete(false);
      }
    }

  }


  const handleKeyDown = (event: any) => {
    if (event.key === 'Enter') {
      fetchClassRoom(1, event.target.value);
    }
  }


  const [teachers, setTeachers] = useState<UserModel[]>([])

  const getTeachers = async () => {
    const { data } = await searchTeacherUser({ pageIndex: 1, pageSize: 500, status: EnumUserStatus.Actived });
    if (data.data) {
      setTeachers(data.data);
    }
  }


  return (
    <>
      <div className='card'>
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1 d-flex align-items-center'>Danh sách lớp học
              <a target='_blank' href='https://docs.google.com/document/d/1RVOdBsEskeCxE6oIuvJXIV3SC-xURiXKQeCMB48Uny4/edit?usp=sharing' className='d-flex ps-2'>
                <span className="material-symbols-rounded fs-2">
                  help
                </span>
              </a>
            </span>
            <span className='text-muted mt-1 fw-semibold fs-7'>Tổng cộng {totalRows} lớp</span>
          </h3>

          <div className='d-flex align-items-center'>
            <div className='me-2'>
              <input type='search' className='form-control form-control-sm' placeholder='Enter để tìm kiếm...' onKeyDown={handleKeyDown} />
            </div>

            <button
              onClick={() => handleCreateClassRoom()}
              className='btn btn-sm btn-primary d-flex'
            >
              <span className="fs-3 material-symbols-rounded me-2">
                add
              </span>
              Thêm lớp học
            </button>

          </div>
        </div>

        {loading && <div className='card-body' >
          <div className="skeleton rounded my-5" style={{ height: 150 }}></div>
        </div>
        }

        <div className='card-body' hidden={loading}>
          <DataTable
            paginationComponentOptions={{
              rowsPerPageText: "Số hàng",
              rangeSeparatorText: "trong"
            }}
            className='select-table'
            columns={columns}
            data={data}
            pagination
            // paginationServer
            paginationTotalRows={totalRows}
            // onChangeRowsPerPage={handlePerRowsChange}
            // onChangePage={handlePageChange}
            responsive
            pointerOnHover
            highlightOnHover
            onRowClicked={(row) => navigate("/class-rooms/" + row.id)}
            noDataComponent="Không có dữ liệu"

          />
        </div>
      </div>
      <UpsertClassRoomModal show={show} handleClose={handleClose} handleSubmit={handleCreate} classRoom={undefined} teachers={teachers} loading={loadingAction} />
      <ConfirmModal show={showDelete} handleClose={handleClose} handleConfirmed={handleDelete} />
    </>
  )
}

export default ClassRoomListPage
