/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { useAuth } from '../../../../app/modules/auth';
import { toAbsoluteUrl } from '../../../helpers';
import UpdateUserModelModal from '../../../../app/common/modals/UpdateUserModelModal';

const HeaderUserMenu: FC = () => {
  const { currentUser, logout } = useAuth();
  const [show, setShow] = useState(false)
  return (
    <>
      <div
        className='menu menu-sub menu-sub-dropdown menu-column menu-rounded menu-gray-600 menu-state-bg menu-state-primary fw-semibold py-4 fs-6 w-275px'
        data-kt-menu='true'
      >
        <div className='menu-item px-3'>
          <div className='menu-content d-flex px-3'>
            <div className='symbol symbol-40px me-5'>
              <img alt='Logo' src={toAbsoluteUrl('/media/avatars/blank.png')} />
            </div>

            <div className='d-flex flex-column'>
              <div className='fw-bold fs-5'>
                <div>{currentUser?.firstName} {currentUser?.lastName}</div>
                <div className='badge badge-light-info fw-bold fs-8'> {currentUser?.userType}</div>
              </div>
            </div>
          </div>
        </div>

        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <a href='#' onClick={() => setShow(true)} className='menu-link px-5'>
            Thông tin cá nhân
          </a>
        </div>
        <div className='separator my-2'></div>

        <div className='menu-item px-5'>
          <a onClick={logout} className='menu-link px-5'>
            Đăng xuất
          </a>
        </div>
      </div>
      <UpdateUserModelModal show={show} handleClose={() => setShow(false)} handleSuccess={() => setShow(false)} />
    </>
  )
}

export { HeaderUserMenu }
