import vi from 'date-fns/locale/vi';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';

import { Field, FieldArray, Form, Formik } from 'formik';
import moment from 'moment';
import { FC } from 'react';
import { Button, Modal } from 'react-bootstrap';
import DatePicker, { registerLocale } from 'react-datepicker';

import { UserModel } from '../../../../common/models/_models';
import { ClassRoomDetailModel, UpsertClassRoomModel } from '../../core/_models';

registerLocale('vi', vi)
type props = {
    handleClose: () => void,
    show: boolean,
    classRoomId?: string
    classRoom?: ClassRoomDetailModel,
    handleSubmit: (data: UpsertClassRoomModel) => void
    teachers: UserModel[]
    loading: boolean
}


const ValidateSchema = Yup.object().shape({

    name: Yup.string()
        .min(2, 'Quá ngắn')
        .max(200, 'Tên dài quá 200 ký tự!')
        .required('Yêu cầu tên'),

    duration: Yup.number()
        .min(2, 'Quá ngắn')
        .max(500, 'Dài quá 500')
        .required('Chưa chọn thời lượng'),

    teacherId: Yup.string()
        .required('Chưa chọn phần này'),

    numberOfSessions: Yup.number()
        .min(2, 'Quá ngắn')
        .max(500, 'Dài quá 500')
        .required('Chưa chọn phần này'),

    timePerWeeks: Yup.array().min(1, 'Quá ít').max(500, 'Dài quá 500').required('Chưa chọn phần này'),

});



const UpsertClassRoomModal: FC<props> = ({ loading, show, handleClose, handleSubmit, classRoom, teachers }) => {

    let timePerweeks = classRoom?.timePerWeeks || [{ dayOfWeek: 'Monday', timeString: '08:00', time: 480 }]

    timePerweeks.forEach(x => x.timeString = moment().startOf('day').add(x.time, 'minutes').format("HH:mm"))

    const initialValues: UpsertClassRoomModel = {
        name: classRoom?.name || '',
        duration: classRoom?.duration || 60,
        numberOfSessions: classRoom?.numberOfSessions || 0,
        startDate: classRoom ? new Date(classRoom.startDate) : new Date(),
        teacherId: classRoom?.teacher?.id || '',
        timePerWeeks: classRoom?.timePerWeeks || [{ dayOfWeek: 'Monday', timeString: '08:00', time: 480 }]
    };

    return (
        <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header closeButton={!loading}>
                <Modal.Title className='d-flex align-items-center'>
                    {classRoom && <span>Sửa lớp học</span>}
                    {!classRoom && <span>Thêm lớp học</span>}
                    <a target='_blank' href='https://docs.google.com/document/d/1RVOdBsEskeCxE6oIuvJXIV3SC-xURiXKQeCMB48Uny4/edit#heading=h.u2t9eym0ej4r' className='d-flex ps-2'>
                        <span className="material-symbols-rounded fs-2">
                            help
                        </span>
                    </a>
                </Modal.Title>
            </Modal.Header>
            <Formik
                initialValues={initialValues}
                validationSchema={ValidateSchema}
                onSubmit={(values) => {
                    let request = values;
                    request.timePerWeeks.forEach(x => {
                        let duration = moment.duration(moment(x.timeString, 'hh:mm').diff(moment().startOf('day')))
                        x.time = duration.asMinutes()
                    })
                    handleSubmit(request);
                }}
                render={({ values, setFieldValue, errors, touched }) => (
                    <Form>
                        <Modal.Body>
                            <div className="row mb-12">
                                <div className='col-lg-12 mb-3'>
                                    <label className="col-form-label required fw-bold fs-6">Tên lớp học</label>
                                    <Field type="text" id="name" name="name" className="form-control form-control-lg mb-3 mb-lg-0" />
                                    {errors.name && touched.name ? (
                                        <div className='text-danger small'>{errors.name}</div>
                                    ) : null}
                                </div>
                                <div className='col-lg-12 mb-3'>
                                    <label className="col-form-label required fw-bold fs-6">Giáo viên giảng dạy</label>
                                    <Field type="text" as="select" id="teacherId" name="teacherId" className="form-control form-control-lg form-select mb-3 mb-lg-0" >
                                        <option>Chọn giáo viên</option>
                                        {
                                            teachers.map(x => <option key={x.id} value={x.id}>{x.firstName} {x.lastName}</option>)
                                        }
                                    </Field>
                                    {errors.teacherId && touched.teacherId ? (
                                        <div className='text-danger small'>{errors.teacherId}</div>
                                    ) : null}
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <label className="col-form-label required fw-bold fs-6">Ngày bắt đầu</label>
                                    <DatePicker
                                        wrapperClassName='w-100'
                                        className='form-control form-control-lg mb-3 mb-lg-0'
                                        dateFormat="dd/MM/yyyy"
                                        minDate={new Date()}
                                        locale="vi"
                                        selected={new Date(values.startDate)}
                                        onChange={(date) => setFieldValue('startDate', date)}
                                    />
                                </div>

                                <div className='col-lg-4 mb-3'>
                                    <label className="col-form-label required fw-bold fs-6">Số buổi</label>
                                    <Field type="number" id="numberOfSessions" name="numberOfSessions" className="form-control form-control-lg mb-3 mb-lg-0" />
                                    {errors.numberOfSessions && touched.numberOfSessions ? (
                                        <div className='text-danger small'>{errors.numberOfSessions}</div>
                                    ) : null}
                                </div>
                                <div className='col-lg-4 mb-3'>
                                    <label className="col-form-label required fw-bold fs-6">Thời lượng (phút)</label>
                                    <Field type="number" id="duration" name="duration" className="form-control form-control-lg mb-3 mb-lg-0" />
                                    {errors.duration && touched.duration ? (
                                        <div className='text-danger small'>{errors.duration}</div>
                                    ) : null}
                                </div>
                                <div className='col-lg-12 mb-3'>
                                    <label className="col-form-label required fw-bold fs-6">Thời gian học hàng tuần</label>
                                    <FieldArray
                                        name="timePerWeeks"
                                        render={arrayHelpers => (
                                            <div>
                                                {values.timePerWeeks && values.timePerWeeks.length > 0 ? (
                                                    values.timePerWeeks.map((friend, index) => (
                                                        <div className='row mb-5' key={index}>
                                                            <div className='col-sm-5'>
                                                                <Field name={`timePerWeeks.${index}.dayOfWeek`} as="select" className="form-control form-select">
                                                                    <option value="Monday">Thứ 2</option>
                                                                    <option value="Tuesday">Thứ 3</option>
                                                                    <option value="Wednesday">Thứ 4</option>
                                                                    <option value="Thursday">Thứ 5</option>
                                                                    <option value="Friday">Thứ 6</option>
                                                                    <option value="Saturday">Thứ 7</option>
                                                                    <option value="Sunday">Chủ nhật</option>
                                                                </Field>
                                                            </div>
                                                            <div className='col-sm-5'>
                                                                <Field type="time" id="name" name={`timePerWeeks.${index}.timeString`} className="form-control form-control-lg mb-3 mb-lg-0" />
                                                            </div>

                                                            <div className='col-sm-2 d-flex'>
                                                                <button
                                                                    className='btn btn-light-danger btn-sm me-3'
                                                                    type="button"
                                                                    onClick={() => arrayHelpers.remove(index)}
                                                                >
                                                                    -
                                                                </button>
                                                                <button
                                                                    className='btn btn-light-success btn-sm'

                                                                    type="button"
                                                                    onClick={() => arrayHelpers.insert(index + 1, { dayOfWeek: 'Monday', timeString: '08:00', time: 480 })} // insert an empty string at a position
                                                                >
                                                                    +
                                                                </button>
                                                            </div>



                                                        </div>
                                                    ))
                                                ) : (
                                                    <button className='btn btn-light-success btn-sm' type="button" onClick={() => arrayHelpers.push({ dayOfWeek: 'Monday', timeString: '08:00', time: 480 })}>
                                                        Thêm thời gian
                                                    </button>
                                                )}
                                            </div>
                                        )}
                                    />
                                    {errors.timePerWeeks && touched.timePerWeeks ? (
                                        <div className='text-danger small'>Không hợp lệ</div>
                                    ) : null}
                                </div>
                            </div>
                        </Modal.Body>
                        <Modal.Footer>
                            <Button variant="secondary" type="button" onClick={handleClose} disabled={loading}>
                                Đóng
                            </Button>
                            <Button variant="primary" type="submit" disabled={loading}>
                                Lưu
                            </Button>
                        </Modal.Footer>
                    </Form>
                )}
            />
        </Modal>
    )
}

export default UpsertClassRoomModal
