import axios from 'axios'
import { BaseListResponse, BaseResponse, ScheduleModel, ScheduleSearchModel, SearchModel, SearchTeacherModel, StudentDetailModel, StudentSearchResult, UpsertStudentModel, UpsertUserModel, UserModel } from '../../../common/models/_models'
import { ImportUserModel, ImportUserResult } from './_models'

const API_URL = process.env.REACT_APP_API_URL
export const API_USER = `${API_URL}/api/users`
export const API_ADMIN = `${API_URL}/api/users/admins`
export const API_TEACHER = `${API_URL}/api/users/teachers`
export const API_STUDENT = `${API_URL}/api/users/students`

export function updateUserStatus(id: string, status: string) {
  return axios.put<BaseResponse<boolean>>(`${API_USER}/${id}/status`, {
    status: status
  })
}



export function importExcel(userType: string, request: ImportUserModel, classRoomId?: string) {
  let formData = new FormData();
  formData.append('file', request.file);
  if (classRoomId) {
    formData.append('classRoomId', classRoomId);
  }

  return axios.post<BaseResponse<ImportUserResult[]>>(`${API_USER}/${userType}/import`, formData)
}

// TEACHER
export function createTeacherUser(request: UpsertUserModel) {
  return axios.post<BaseResponse<string>>(API_TEACHER, request)
}

export function updateTeacherUser(id: string, request: UpsertUserModel) {
  return axios.put<BaseResponse<boolean>>(`${API_TEACHER}/${id}`, request)
}

export function deleteTeacherUser(id: string) {
  return axios.delete<BaseResponse<boolean>>(`${API_TEACHER}/${id}`)
}

export function searchTeacherUser(request: SearchTeacherModel) {
  return axios.post<BaseListResponse<UserModel>>(`${API_TEACHER}/search`, request)
}

export function getTeacherSchedule(id: string, request: ScheduleSearchModel) {
  return axios.post<BaseResponse<ScheduleModel[]>>(`${API_TEACHER}/${id}/schedules`, request)
}


// ADMIN
export function createAdminUser(request: UpsertUserModel) {
  return axios.post<BaseResponse<string>>(API_ADMIN, request)
}

export function updateAdminUser(id: string, request: UpsertUserModel) {
  return axios.put<BaseResponse<boolean>>(`${API_ADMIN}/${id}`, request)
}

export function deleteAdminUser(id: string) {
  return axios.delete<BaseResponse<boolean>>(`${API_ADMIN}/${id}`)
}
export function searchAdminUser(request: SearchModel) {
  return axios.post<BaseListResponse<UserModel>>(`${API_ADMIN}/search`, request)
}


// STUDENT
export function createStudentUser(request: UpsertStudentModel) {
  return axios.post<BaseResponse<string>>(API_STUDENT, request)
}

export function updateStudentUser(id: string, request: UpsertStudentModel) {
  return axios.put<BaseResponse<boolean>>(`${API_STUDENT}/${id}`, request)
}

export function deleteStudentUser(id: string) {
  return axios.delete<BaseResponse<boolean>>(`${API_STUDENT}/${id}`)
}

export function searchStudentUser(request: SearchModel) {
  return axios.post<BaseListResponse<StudentSearchResult>>(`${API_STUDENT}/search`, request)
}


export function getStudentSchedule(id: string, request: ScheduleSearchModel) {
  return axios.post<BaseResponse<ScheduleModel[]>>(`${API_STUDENT}/${id}/schedules`, request)
}

export function getStudentById(id: string) {
  return axios.get<BaseResponse<StudentDetailModel>>(`${API_STUDENT}/${id}`)
}

